import {
  ButtonDelete,
  ButtonDeleteCircle,
  ButtonDeleteImg,
  ContainerCardEvents,
  ContainerCardDetails,
  CardDateAndImg,
  ContainerCardImg,
  CardBody,
  CardBodyFd,
  ContainerCardBodyText,
  CardHead,
  CardOverlay,
  ContainerCardBodyTextGh,
  InternalConteiner,
} from "./style";

import { useSelector } from "react-redux";
import useMediaQuery from "../../../../../hooks/useMediaQuery";
import { Link } from "react-router-dom";

export function SubscribedCard({
  data,
  singleEvent,
  showModalCannotDelete,
  showModalDelete,
}) {
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const lang = useSelector((state) => state.coreComponents.language);
  const theme = useSelector((state) => state.coreComponents.pageLight);
  const isGiftHouse = singleEvent?.additionalEventParams?.find(
    (el) => el === "giftHouse"
  );
  const isFamilyDay = singleEvent?.additionalEventParams?.find((el) =>
    el.includes("familyDay")
  );

  const getSubscriptionDay = (startDate, endDate) => {
    let result = startDate ? `${new Date(startDate)?.getDate()}` : "";
    result = endDate ? `${result}-${new Date(endDate)?.getDate()}` : result;
    return result;
  };
  const getSubscriptionMonth = (startDate, endDate) => {
    let result = startDate
      ? new Date(startDate)?.toLocaleString(lang, {
          month: "short",
        })
      : "";

    result =
      result &&
      endDate &&
      result !==
        new Date(endDate)?.toLocaleString(lang, {
          month: "short",
        })
        ? `${result}-${new Date(endDate)?.toLocaleString(lang, {
            month: "short",
          })}`
        : result;
    return result;
  };

  const getSubscriptionHours = (startDate, endDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      hour: "2-digit",
      minute: "2-digit",
    });
    result = `${result} - ${new Date(endDate)?.toLocaleString(lang, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const redirectToDetail = (singleEvent) => {
    let link, basePath, endPoint;
    const path = `${singleEvent?.eventDetailLink}?eventCode=`;

    if (singleEvent?.eventType === "internal-events") {
      basePath = `/generic-subscription-detail?path=`;
    } else if (isFamilyDay) {
      basePath = `/subscription-detail-family-day?path=`;
    } else if (isGiftHouse) {
      basePath = `/subscription-detail-gh?path=`;
    } else {
      basePath = `/subscription-detail?path=`;
    }

    if (isGiftHouse) {
      endPoint = singleEvent?.eventCodeList;
    } else {
      endPoint = singleEvent?.eventCodeList[0];
    }
    link = `${basePath}${path}${endPoint}`;

    return link;
  };

  const eventAppointment = singleEvent?.subDetail?.appointments?.find(
    (el) => el.type === "Event"
  );
  const passAppointment = singleEvent?.subDetail?.appointments?.find(
    (el) => el.type === "PassPickup"
  );

  return (
    singleEvent?.eventDetailLink && (
      <ContainerCardEvents light={theme === "dark" ? true : false}>
        <ContainerCardImg>
          <CardOverlay />
          <CardDateAndImg>
            {singleEvent?.eventType !== "internal-events" && !isGiftHouse && (
              <>
                <p>
                  {getSubscriptionDay(
                    singleEvent?.subDetail?.eventStartDate,
                    singleEvent?.subDetail?.eventEndDate
                  )}
                </p>
                <p>
                  {getSubscriptionMonth(
                    singleEvent?.subDetail?.eventStartDate,
                    singleEvent?.subDetail?.eventEndDate
                  )}
                </p>
              </>
            )}
            {isDesktop ? (
              <img src={singleEvent?.image} />
            ) : (
              <img
                src={singleEvent?.fileReferenceMobile || singleEvent?.image}
              />
            )}
          </CardDateAndImg>
        </ContainerCardImg>
        <ContainerCardDetails height={!passAppointment ? "true" : "false"}>
          <CardHead>
            {singleEvent?.title}
            {!singleEvent?.endSubBool && (
              <button
                onClick={(e) =>
                  singleEvent?.canDeleteSub === false
                    ? showModalCannotDelete(e, true)
                    : showModalDelete(
                        e,
                        true,
                        singleEvent?.subDetail?.subscriptionGuid
                      )
                }
              >
                <ButtonDelete>
                  <ButtonDeleteImg />
                  <ButtonDeleteCircle />
                </ButtonDelete>
              </button>
            )}
          </CardHead>
          {singleEvent?.eventCodeList?.length > 1 ? (
            <CardBody>
              <ContainerCardBodyTextGh>
                <p>{singleEvent?.cardSummaryTxt}</p>
              </ContainerCardBodyTextGh>
            </CardBody>
          ) : (
            <CardBody column={passAppointment ? "true" : "false"}>
              {passAppointment && (
                <ContainerCardBodyText
                  height={!passAppointment ? "true" : "false"}
                >
                  <InternalConteiner>
                    <label>
                      {`${data?.slotLabel} ${passAppointment?.typeTxt}`}
                    </label>
                    <p>
                      {getSubscriptionDay(passAppointment?.startDate)}{" "}
                      {getSubscriptionMonth(
                        passAppointment?.startDate,
                        passAppointment?.endDate
                      ).toUpperCase()}{" "}
                      {getSubscriptionHours(
                        passAppointment?.startDate,
                        passAppointment?.endDate
                      )}
                    </p>
                  </InternalConteiner>
                  <InternalConteiner>
                    <label>{data?.locationLabel}</label>
                    <p>{passAppointment?.locationTxt}</p>
                  </InternalConteiner>
                </ContainerCardBodyText>
              )}
              <ContainerCardBodyText>
                <InternalConteiner>
                  <label>
                    {singleEvent?.pickSlotTitle &&
                    singleEvent?.eventType === "internal-events"
                      ? singleEvent?.slotLabel
                      : `${data?.slotLabel} ${eventAppointment?.typeTxt}`}
                  </label>
                  {singleEvent?.pickSlotTitle &&
                  singleEvent?.eventType === "internal-events" ? (
                    <p>{eventAppointment?.title}</p>
                  ) : (
                    <p>
                      {getSubscriptionDay(eventAppointment?.startDate)}{" "}
                      {getSubscriptionMonth(
                        eventAppointment?.startDate,
                        eventAppointment?.endDate
                      ).toUpperCase()}{" "}
                      {getSubscriptionHours(
                        eventAppointment?.startDate,
                        eventAppointment?.endDate
                      )}
                    </p>
                  )}
                </InternalConteiner>
                {eventAppointment?.locationTxt && (
                  <InternalConteiner>
                    <label>{data?.locationLabel}</label>
                    <p>{eventAppointment?.locationTxt}</p>
                  </InternalConteiner>
                )}
              </ContainerCardBodyText>
            </CardBody>
          )}
          <Link to={redirectToDetail(singleEvent)}>{data?.detailCtaLabel}</Link>
        </ContainerCardDetails>
      </ContainerCardEvents>
    )
  );
}

import md5 from "md5";

const CONSULTANT_DIRECTION = "consultant";

export const ChangeTitleDoc = (
  subjectCode,
  aggregatedDirection,
  title,
  path,
  setManually
) => {
  title = title?.trim();

  //gestione consulenti, per loro si manda come direction consultant
  if (subjectCode.startsWith("E_")) {
    aggregatedDirection = CONSULTANT_DIRECTION;
  }

  if (setManually && title && title !== document.title) {
    SwitchTitle(subjectCode, aggregatedDirection, title);
  } else if (
    title &&
    title !== document.title &&
    window.location.href.includes(path)
  ) {
    SwitchTitle(subjectCode, aggregatedDirection, title);
  }
};

const SwitchTitle = (subjectCode, aggregatedDirection, title) => {
  document.title = title;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "content_loaded",
    id: md5(subjectCode),
    department: aggregatedDirection,
  });
};

export const ChangeTitlePublicDoc = (title, path) => {
  if (
    title &&
    title !== document.title &&
    window.location.href.includes(path)
  ) {
    document.title = title;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "content_loaded",
    });
  }
};
